import React from 'react'
import { buildClass } from '@util/tag'
import useTranslation from 'next-translate/useTranslation'

function LoadingFullScreen() {
    const { t } = useTranslation()

    return (
        <div
            className={buildClass(
                'flex flex-col justify-center items-center',
                'w-full h-full relative py-20'
            )}
        >
            <img src="/img/GIFs/Loading.gif" className="w-24" />
            <h2 className="sm:text-base text-2xl font-bold  text-secondary-600">
                {t('common:wait')}
            </h2>
        </div>
    )
}

export default LoadingFullScreen
