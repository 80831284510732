import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import connectText from '@hooks/connectText'
import PrimaryButton from '@components/shared/primary-buttons/primary-button'
import { useRouter } from 'next/router'

const Header = () => {
    const { t, lang } = useTranslation()
    const connect = connectText('common:home')
    const router = useRouter()

    return (
        <div
            className="flex flex-col gap-5 md:items-start items-center justify-center w-full h-[689px] py-12 md:py-0 px-[10px] lg:px-[100px]"
            style={{
                backgroundImage: `${
                    lang === 'ar'
                        ? "url('/img/home/solutions/main-ar.png')"
                        : "url('/img/home/solutions/main-en.png')"
                }`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }}
        >
            <img
                src="/img/logos/upsilon-logo-light.svg"
                alt="logo"
                className="h-[102px] mt-8 mx-[-47px]"
            />

            <div className="flex flex-col gap-4 lg:gap-10 md:items-start items-center text-white">
                <div className="flex flex-col gap-2 md:items-start items-center text-white">
                    <div className="text-[22px] lg:text-[28px] font-bold">
                        {t(connect('headerText1'))}
                    </div>
                    <div
                        className={`text-[18px] lg:text-[24px] font-medium ${
                            lang === 'ar' ? 'md:text-right' : 'md:text-left'
                        } text-center`}
                    >
                        {t(connect('headerText2'))}
                    </div>
                </div>

                <PrimaryButton
                    onClick={() => router.push('/become-a-customer')}
                >
                    {t(connect('becomeACustomer'))}
                </PrimaryButton>
            </div>
        </div>
    )
}

export default Header
